export const loggerAction = {
  onload: 'seller-landing-page',
  onAddressFocus: 'seller-landing-header-address-onfocus',
  onAddressData: 'seller-landing-header-address-data',
  headerButton: 'seller-onboarding-cta-link',
  headerLink: 'buyer-cta-link',
  stepSectionButton: 'seller-landing-button'
}

export const HEADHELMET = {
  title: 'Venda seu imóvel com os melhores corretores.',
  description:
    'Seu imóvel será divulgado nos principais portais do mercado e nas redes sociais, alcançando milhares de compradores.',
  url: 'https://emcasa.com/vender',
  image: `${process.env.CLOUDINARY_IMAGE_URL}/share/share-2`
}

export const LANDINGHEADER = {
  title: (
    <>
      <strong>Venda seu imóvel</strong> mais rápido, com mais praticidade e
      segurança
    </>
  ),
  text:
    'A EmCasa te ajuda durante todo o processo de venda, do anúncio até a documentação.',
  button: 'Começar o anúncio',
  anchor: 'Quer comprar um imóvel?'
}

export const EMCASA_VIDEO = 'https://www.youtube.com/embed/F-_jRTAA0R8?rel=0'

export const LANDINGSECTION_STEPS = {
  label: 'Passo a passo',
  title: 'Venda seu imóvel em 4 passos',
  content:
    'A Assessoria Jurídica da EmCasa garante um  processo mais prático, transparente e seguro.',
  list: [
    {
      title: 'Anuncie de graça',
      text:
        'Nosso fotógrafo profissional visita seu imóvel para fazer a sessão de fotos gratuitamente. Seu anúncio fica ainda mais atrativo.'
    },
    {
      title: 'Alcance milhares de compradores',
      text:
        'Investimos para divulgar o anúncio do seu imóvel em redes sociais e grandes portais de imóveis. Suas chances de vender ficam ainda maiores.'
    },
    {
      title: 'Conte com os melhores corretores',
      text:
        'Nossos corretores são treinados e possuem todas as ferramentas necessárias para proporcionar a melhor experiência de venda de imóvel.'
    },
    {
      title: 'Venda com segurança',
      text:
        'Nossa assessoria jurídica te auxilia com toda a documentação, para que a sua venda aconteça sem preocupações.'
    }
  ],
  button: 'Anunciar meu imóvel'
}

export const LANDINGSECTION_EVALUATION = {
  label: 'Avaliação online grátis',
  title: 'Descubra o preço de venda recomendado para o seu imóvel',
  content:
    'Nossa avaliação considera as características do imóvel, sua localização e os preços de outras transações similares do mercado.'
}

export const PARTNERSLIST = [
  {
    title: (
      <>
        Programa <strong>Corretor Parceiro</strong>
      </>
    ),
    text: (
      <>
        Ganhe <strong>15% da comissão da EmCasa</strong> quando o imóvel for
        vendido. Basta ser corretor e nos indicar imóveis disponíveis para
        venda.
      </>
    ),
    button: {
      log: 'seller-landing-partnerbroker-cta-link',
      to: '/corretor-parceiro',
      label: 'Quero participar'
    }
  }
]
